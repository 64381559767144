export const SIGN_IN_BUTTON = "Sign In to Order";
export const LOCK_TIME_HOUR = 9;
export const TRANS_KEYS = {
    translation :"translation",
    INVOICES:"Invoices",
    INVOICE_DETAILS:"Invoices.Invoice_Details",
    INVOICE_DETAILS_TABLE:"Invoices.Invoice_Details.Invoice_Detail_Table",
    USER_LIST : "Users",
    USER_DATA :"UserDetails",
    COMPANIES_LIST:"Companies",
    MAINTENANCE:'maintenance',
    APPROVALS:"approval",
    LEFT_MENU:'left_menu',
    ORDER:"order",
    ORDER_CONFIRMATION:"orderConfirmation",
    NOTIFICATION: "notification",
    FORECAST:'forecast'
}

export const REGEX = {
    NUMBER:  /^\d+$/,
    ALPHANUMERIC_WITH_CHARACTER: /^[A-Za-z0-9-_]+$/,
    PHONE_NUMBER_INCLUDING_PLUS: /^[0-9+ ]+$/,
    NUMBER_WITH_SPECIAL_CHARACTER: /^[0-9.-]+$/,
    ALPHANUMERIC_WITHOUT_SPECIAL_CHARACTER: /^[A-Za-z0-9]+$/,
    ALPHABET_WITH_SPECIAL_CHARACTER: /^[A-Za-z-_.ØÆÅøæå ]+$/,
    ALPHANUMERIC_WITH_SPECIAL_CHARACTER: /^[a-zA-Z0-9_ØÆÅøæå .,:-]{0,250}$/
  }

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const UNITS = {
    PERCENTAGE: "%",
    EURO_PER_MEGA_WATT: "€/MWh",
    KG: "kg",
    KG_PER_DAY: "kg/day",
    DKK_PER_MWH: "DKK/MWh"
}
export const NOTIFICATION_STATUS = Object.freeze({
    SENT: 1,
    CREATED: 2,
    SEEN: 3,
})

export const NOTIFICATION_TYPE = {
    ORDER_CREATED: "ORDER_CREATED",
    ORDER_UPDATED: "ORDER_UPDATED",
    ORDER_CANCELLED: "ORDER_CANCELLED",
    ORDER_APPROVED: "ORDER_APPROVED",
    ORDER_REJECTED: "ORDER_REJECTED",
    BRP_PLAN_CHANGED: "BRP_PLAN_CHANGED",
    MAINTENANCE_CREATED: "MAINTENANCE_CREATED",
    MAINTENANCE_UPDATED: "MAINTENANCE_UPDATED",
    MAINTENANCE_DELETED: "MAINTENANCE_DELETED"
}